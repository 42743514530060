import react, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classes from "./Login.module.css";
import {useDispatch} from "react-redux";
import {authenticate} from "../stores/actions/auth";
import LoadingOverlay from "react-loading-overlay";
import CustomModal from "../ui/CustomModal";



const Login = () => {

    useEffect(() => {

        // Check if fullscreen is supported and ask for permission
        if (document.fullscreenEnabled) {
            // You can customize this part to ask for user permission, e.g., show a modal
            // and call requestFullScreen if the user grants permission.
            document.exitFullscreen().catch(err => console.log(err));
        } else {
            console.log("Fullscreen not supported");
        }
    }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    // const [password, setPassword] = useState('admin@1234');
    const [password, setPassword] = useState('');
    const userNameChangeHandler = (event) => {
        setUsername(event.target.value);
    };
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    };
    const navigationHandler = async (event) => {
        event.preventDefault();
        try{
            console.log("Username==>",username,"  Password==>",password);
            setIsLoading(true);
            await dispatch(authenticate(username,password));
            setIsLoading(false);
            navigate('isAuth/examtype'); //change to show loa screen
            // navigate('isAuth/loa');
        }catch(error){
            setIsLoading(false);
            console.log(error);
        }

    };
    return(
        <react.Fragment>
            <div className={classes.outercontainer}>
                {isLoading && <div className={classes.loading}>
                 <LoadingOverlay active={isLoading} spinner />
                </div>}
                {!isLoading && <div className={classes.innercontainer}>
                    {/*<div className={classes.page}>*/}
                    {/*<div className={classes.container}>*/}
                    {/*    <img className={classes.image} src={require('../src/assets/vecteezy_illustration-vector-graphic-cartoon-character-of-home-study_6584582.jpg')} alt={""}/>*/}
                    {/*</div>*/}
                    <div className={classes.icon}>
                        <img className={classes.image} src={require('../assets/student.png')} alt={""}/>
                    </div>
                    <div className={classes.form}>
                        <div className={classes.title}>Student Login</div>
                        <form>
                            <div className={classes.username}>
                                <label>
                                    {/*<div className={classes.labelContainer}>Username</div>*/}
                                    <input type="text" name="username" placeholder={"Username"} onChange={userNameChangeHandler} size={30}/>
                                </label>
                            </div>
                            <div className={classes.password}>
                                <label>
                                    {/*<div className={classes.labelContainer}>Password</div>*/}
                                    <input type="text" name="password" placeholder={"Password"} onChange={passwordChangeHandler} size={30}/>
                                </label>
                            </div>
                            {/*<div className={classes.password} style={{ display: 'none' }}>*/}
                            {/*    <label>*/}
                            {/*        /!*<div className={classes.labelContainer}>Password</div>*!/*/}
                            {/*        <input type="text" name="password" placeholder={"Password"} onChange={passwordChangeHandler} size={30} value={"admin@1234"}/>*/}
                            {/*    </label>*/}
                            {/*</div>*/}



                            {/*<div className={classes.password}>*/}
                            {/*    <label>*/}
                            {/*        <div className={classes.labelContainer}>Confirm Password</div>*/}
                            {/*        <input type="text" name="confirm" size={70}/>*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                                <div className={classes.button}>
                                    <button type="submit" onClick={navigationHandler}>Log in</button>
                                </div>
                        </form>
                    </div>
                    {/*</div>*/}
                </div>}
            </div>
        </react.Fragment>
    );
};

export default Login;
